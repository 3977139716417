import { useStyletron } from "baseui";
import { DisplaySmall, ParagraphMedium } from "baseui/typography";
import React from "react";
import { Helmet } from "react-helmet";
import { DocsInterface } from "../src/chrome/DocsInterface";

const NotFound = (props: any) => {
  const [css, $theme] = useStyletron();

  return (
    <DocsInterface {...props} showLeftSideNav={false}>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div
        data-testid="docsNotFoundMessage"
        className={css({
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "center",
          marginTop: $theme.sizing.scale1200,
          display: "flex",
          flexGrow: 2,
          flexDirection: "column",
          justifyContent: "center",
        })}
      >
        <DisplaySmall>Page not found</DisplaySmall>
        <ParagraphMedium>Sorry about that.</ParagraphMedium>
      </div>
    </DocsInterface>
  );
};

export default NotFound;
